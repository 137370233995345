@font-face {
  font-family: 'TTLaundryGothicB';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2403-2@1.0/TTLaundryGothicB.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

/* Global styles and responsive settings */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'TTLaundryGothicB', sans-serif;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130vh; /* Reduce height to move content up */
  box-sizing: border-box;
  padding-top: 30px; /* Adjust padding for header */
}

.wrapper-question {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Reduce height to move content up */
  box-sizing: border-box;
  padding-top: 30px; /* Adjust padding for header */
}

.wrapper-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Reduce height to move content up */
  box-sizing: border-box;
  padding-top: 30px; /* Adjust padding for header */
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px; /* Reduce padding */
  padding-top: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  padding-top: 50px; /* Ensure padding for header */
}

@media (min-width: 768px) {
  .container {
    padding: 20px; /* Adjust padding for larger screens */
  }
}

header {
  background-color: #FFD6FF;
  padding: 1px;
  text-align: center;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 2px;
  opacity: 0.8; /* 글씨 불투명도 설정 */
  font-size: 12px; /* 글씨 크기 줄이기 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 텍스트 그림자 추가 */
}

footer {
  background-color: #ffffff;
  padding: 1px;
  text-align: center;
  position: relative;;
  bottom : 0;
  left: 0;
  width: 100%;
  line-height: 2px;
  opacity: 0.8; /* 글씨 불투명도 설정 */
  margin-top: 10px;
  font-size: 12px; /* 글씨 크기 줄이기 */
}

.questionbutton {
  display: flex;
  justify-content: center; /* 가로 정렬: 중앙 정렬 */
  align-items: center;    /* 세로 정렬: 중앙 정렬 */
}

h2 {
  margin-top: 0; /* Ensure no extra margin */
  padding-top: 10px; /* Add padding for spacing */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 70px;
}

.content {
  flex: 1;
  margin-top: 10px;
}

@keyframes dyongEffect {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
}

.dyong-container {
  display: inline-block;
  animation: dyongEffect 0.5s ease-in-out 3;
  text-align: center;
}

.youtube-container {
  width: 100%;
  max-width: 560px;
  margin: auto;
}

.youtube-iframe {
  width: 300;
  height: 150px;
}
