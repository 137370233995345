button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'TTLaundryGothicB', sans-serif; /* 버튼에 폰트 적용 */
  box-shadow: 4px 4px 0px rgba(218, 74, 7, 0.7); /* 그림자 추가 */
}

button:focus {
  outline: none;
}

button > span {
  vertical-align: middle;
}

.button--ujarak {
  overflow: hidden; /* 추가 */
  position: relative; /* 추가 */
  z-index: 0; /* 추가 */
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
}

.button--ujarak::after { /* 추가 */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #37474f;
  z-index: -1;
  transition: all 0.4s cubic-bezier(0.2, 1, 0.3, 1);
  transform: translateX(-100%);
}

.button--ujarak.button--round-s::before {
  border-radius: 15px;
}

.button--ujarak.button--inverted::before {
  background: #7986CB;
}

.button--ujarak,
.button--ujarak::before {
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--ujarak:hover {
  color: #fff;
  border-color: #37474f;
}

.button--ujarak:hover::after { /* 추가 */
  transform: translateX(0);
}

.button--ujarak.button--inverted:hover {
  color: #37474F;
  border-color: #fff;
}

.button--border-medium {
  border: 3px solid;
}

.button--round-s {
  border-radius: 15px;
}

.button--text-thick {
  font-weight: 600;
}


/* Colours */
.fourth {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 3px solid #f1c40f;
  border-radius: 4em;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'TTLaundryGothicB', sans-serif; /* 버튼에 폰트 적용 */
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
}

.fourth:hover {
  background-position: 0;
}
