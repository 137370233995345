/* progressBar.css */
.progress-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .progress-bar-fill {
    height: 20px;
    background-color: #f1b46b;
    width: 0;
    transition: width 0.2s;
  }
  